import { Link } from "gatsby"
import React, { useContext, useState, useCallback } from "react"
import { AlternateLinksContext } from "./wrapWithI18nProvider"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Languages = () => {
  const alternateLinks = useContext(AlternateLinksContext) || []
  const { i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(!open), [open])
  return (
    <LanguagesContainer onClick={toggle} open={open}>
      {i18n?.language?.toUpperCase()}
      {open && (
        <Dropdown>
          {alternateLinks
            .filter(link => link.language !== i18n.language)
            .map((link, i) => (
              <DropdownItem key={`language.${link.language}`}>
                <Link
                  to={link.path}
                  hrefLang={link.language}
                  style={{
                    textDecoration: `none`,
                  }}
                >
                  {link?.language?.toUpperCase()}
                </Link>
              </DropdownItem>
            ))}
        </Dropdown>
      )}{" "}
    </LanguagesContainer>
  )
}
Languages.propTypes = {}
Languages.defaultProps = {}
export default Languages

const LanguagesContainer = styled.div`
  position: relative;
  cursor: pointer;
  border: black solid thin;
  border-bottom-color: ${({ open }) => (open ? "transparent" : "black")};
  padding: 5px 10px;
  user-select: none;
`
const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  border: black solid thin;
  border-top-color: transparent;
  background-color: white;
`
const DropdownItem = styled.div`
  padding: 5px 10px;
  a {
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      color: black;
    }
  }
`
