import React from "react"

const Logo = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49707 0V1.54518H0V4.29769H1.49707V11.9275H1.49752V19.8952H0.144775V22.6477H1.49752V23.8551H4.25005V22.6477H12.0724H12.0724H19.6541V23.9999H22.4066V22.6477H24V19.8952H22.4066V12.0724H19.6541V19.8952H12.0724H12.0724H4.25005V11.9275H4.2496V4.29769H11.9276V1.54518H4.2496V0H1.49707ZM22.4068 1.54518H23.8553V4.29769H22.4068V12.0723H19.6543V4.29769H11.9277V1.54518H19.6543V0.144789H22.4068V1.54518ZM16.5151 6.03621H7.34001V7.58142H5.98779V16.7565H7.34009V18.1567H16.5152V16.7565H17.9156V7.58142H16.5151V6.03621ZM15.1631 15.4042V8.78872H8.74032V15.4042H15.1631ZM13.301 10.6987H10.4094V13.5903H13.301V10.6987Z"
        fill="#4F4D4D"
      />
    </g>
    <defs>
      <filter
        id="filter0_i"
        x="0"
        y="0"
        width={size}
        height="27.9999"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
)
Logo.defaultProps = {
  size: 50,
}
export default Logo
