/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Link from "./link"

const Layout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Header />
      <Body>{children}</Body>
      <Footer>
        <FooterCenter>
          <ContactsList>
            {CONTACTS.map(({ title, url, color }) => (
              <ContactItem
                key={`contact.${title}`}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                color={color}
              >
                {title}
              </ContactItem>
            ))}
          </ContactsList>
        </FooterCenter>
        <FooterLeft>
          <FooterLink
            as={Link}
            to={`/privacy`}
            activeStyle={{ textDecoration: "underline" }}
          >
            {t("footer.privacy")}
          </FooterLink>
        </FooterLeft>
        <FooterRight>{`© ${new Date().getFullYear()} CLAPESQUE`}</FooterRight>
      </Footer>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const Body = styled.main`
  flex: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  padding: 40px 20px 60px 20px;
`
const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  padding: 30px;
  @media (max-width: 1100px) {
    display: block;
    text-align: center;
    line-height: 30px;
  }
`
const FooterLeft = styled.div`
  @media (min-width: 1100px) {
    position: absolute;
    left: 30px;
    bottom: 30px;
  }
`
const FooterCenter = styled.div`
  flex: 1;
`
const FooterRight = styled.div`
  @media (min-width: 1100px) {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
`
const FooterLink = styled.a`
  color: black;
  text-decoration: none;
  text-transform: uppercase;
`
const ContactsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    display: block;
    text-align: center;
    margin: 0;
  }
`

const ContactItem = styled.a`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
  margin: 0 10px;
  transition: color 100ms ease;
  &:hover {
    color: ${({ color }) => color};
  }
  @media (max-width: 700px) {
    margin: 0;
  }
`

const CONTACTS = [
  {
    title: "Instagram",
    url: "https://www.instagram.com/clapesque/",
    color: "#fbad50",
  },
  {
    title: "Linkedin",
    url: "https://www.linkedin.com/company/clapesque/",
    color: "#0e76a8",
  },
  {
    title: "Vimeo",
    url: "https://vimeo.com/clapesque/",
    color: "#1ab7ae",
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com/clapesque/",
    color: "#3b5998",
  },
]
