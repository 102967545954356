import Link from "./link"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Languages from "./languages"
import Logo from "./logo"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const headerLinks = [
  {
    name: "aboutUs",
    url: "/about-us",
  },
  {
    name: "projects",
    url: "/projects",
  },
  {
    name: "contact",
    url: "/contact",
  },
]

const Header = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const siteTitle = data?.site?.siteMetadata?.title
  const logoProps = data?.logo?.childImageSharp || {}
  /*
  console.log("Got data.", { siteTitle, logoProps })
    <Image
    {...logoProps}
    style={{ width: 30, height: 30, verticalAlign: "bottom" }}
  />
  */
  return (
    <Container>
      <LogoContainer>
        <Link to={`/`}>
          <Logo />
        </Link>
      </LogoContainer>
      {headerLinks.map(({ name, url }, index) => (
        <HeaderLink
          key={`header.link.${index}`}
          as={Link}
          to={url}
          activeStyle={{ textDecoration: "underline" }}
        >
          {t(`header.nav.${name}`)}
        </HeaderLink>
      ))}
      <LanguagesContainer>
        <Languages />
      </LanguagesContainer>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Container = styled.div`
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    display: block;
    margin-top: -5px;
  }
`

const HeaderLink = styled.a`
  color: black;
  text-decoration: none;
  margin: 0 30px;
  @media (max-width: 1100px) {
    display: block;
    text-align: center;
    line-height: 30px;
  }
`

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  transform: scale(1.3);
  transform-origin: top left;
  filter: brightness(0);
  z-index: 3;
`

const LanguagesContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
`
