import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

export const useLink = url => {
  const { i18n } = useTranslation()
  return ["/", i18n.language, url].join("")
}
export default ({ to, ...props }) => {
  return <Link to={useLink(to)} {...props} />
}
